import React from 'react';
import { Box, Chip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  firstChip: {
    margin: '0.5vh',
  },
  multiline: {
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
  }
}));

const CRMTitle = ({currentCase}) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {currentCase?.data?.title}
        <Chip 
          label={currentCase?.data?.section} 
          variant="outlined" 
          size="small"
          color="primary"
          className={`${classes.firstChip} ${classes.multiline}`}
        />
        {currentCase?.data?.category &&
          <Chip
            label={currentCase?.data?.category} 
            variant="outlined"
            size="small"
            color="secondary"
            className={classes.multiline}
          />
        }
      </Typography>
    </Box>
  );
}

export default CRMTitle