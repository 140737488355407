import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle, Input, Lock } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { signOut } from "../../../../actions";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { history } from '../../../../config';
import { useMediaQuery } from "react-responsive";
import Settings from "../../../../settings.yml";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MailIcon from '@material-ui/icons/Mail';
import EcoIcon from '@material-ui/icons/Eco';
import ErrorIcon from '@material-ui/icons/Error';
import EvStationIcon from '@material-ui/icons/EvStation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.contrastText
  },
}));

const UserSection = props => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    props.signOut();
  };

  const handleChangePassword = () => {
    history.push("/reset")
  };

  const handleCRMRoute = (ruta) => {
    history.push(`/solicitudes/${ruta}`)
  };

  const handleCRMPendingCasesRoute = () => {
    history.push(`/pendientes`)
  };

  const CRMViewsIcon = (code) => {
    switch (code) {
      case 'AUTOCONS':
        return <EcoIcon fontSize="small"/>
      case 'RECLAMACIO':
        return <ErrorIcon fontSize="small"/>
      case 'EVEHICLE':
        return <EvStationIcon fontSize="small"/>
      case 'SOL_CRM':
      default:
        return <MailIcon fontSize="small"/>
    }
  }

  const getCRMViews = (props) => {
    if(Settings.crm_enabled && props && props?.sections){
      return (props.sections.map((element) => 
        <MenuItem onClick={() => {handleCRMRoute(element.id)}} key={element.id}>
          { element.ov_icon_svg ?
              <ListItemIcon>
                {<svg
                  height="24px"
                  viewBox={element.ov_icon_svg.match(/viewBox="([^"]*)"/)?.[1]}
                  width="24px"
                >
                  <path fill='#757575' d={element.ov_icon_svg.match(/<path d="([^"]*)"/)?.[1]} />
                </svg>}
              </ListItemIcon>
            :
              element.code ?
              <ListItemIcon>
                {CRMViewsIcon(element.code)}
              </ListItemIcon>
            :
              undefined
          }
          <ListItemText primary={element.name}/>
        </MenuItem>
      ))
    } else {
      return [];
    }
  }

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const crmViews = getCRMViews(props);

  return (
    <div className={classes.root}>
      <Button
        aria-controls="menu-appbar"
        aria-haspopup="true"
        size="large"
        onClick={handleMenu}
        endIcon={<AccountCircle className={classes.title}/>}
      >
        {
          !isTabletOrMobile &&
            <Typography
              variant="subtitle1"
              className={classes.title}
            >
              {props.userName}
            </Typography>
        }
      </Button>
      <Menu
        id="menu-appbar"
        elevation={3}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={open}
        onClose={handleClose}
        styles={{border: '1px solid #d3d4d5'}}
      >
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <Input fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary={t('logout')}/>
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <Lock fontSize="small"/>
          </ListItemIcon>
          <ListItemText primary={t('change_password')}/>
        </MenuItem>
        { props && props.allPendingCases?.length > 0 &&
          <MenuItem onClick={handleCRMPendingCasesRoute}>
            <ListItemIcon>
              <AccessTimeIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText primary={t('crm_pending_cases_title')}/>
          </MenuItem>
        }
        {crmViews}
      </Menu>
    </div>
  );
};


const actionCreators = {
  signOut: signOut,
};

const mapStateToProps = state => {
  return {
    userName: state.user.name
  };
};

export default connect(
  mapStateToProps, actionCreators
)(UserSection);