import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCRMCaseDetail,
  fetchCRMCases,
  fetchCRMCaseSections
} from "../../../actions";

const useCRM = (currentSectionId=undefined, currentCaseId=undefined) => {
  const sections = useSelector((state) => state.CRMCases?.sections);
  const loadingSections = useSelector(
    (state) => state?.CRMCases?.loadingSections
  );
  const loadingCasesList = useSelector(
    (state) => state?.CRMCases?.loadingCasesList
  );
  const loadingCaseDetail = useSelector(
    (state) => state?.CRMCases?.loadingCaseDetail
  );

  const dispatch = useDispatch();
  
  const [currentSection, setCurrentSection] = useState(null);
  const [currentCase, setCurrentCase] = useState(null);

  useEffect(() => {
    if(!sections.length) {
      dispatch(fetchCRMCaseSections());
    }
    else {
      const filteredSection = sections?.filter(
        section => section.id === parseInt(currentSectionId)
      )[0]

      setCurrentSection(filteredSection);

      if (!filteredSection?.cases) {
        dispatch(fetchCRMCases(currentSectionId));
      }
      else {
        setCurrentSection(filteredSection);
      }
    }
  }, [
    sections.length,
    currentSectionId,
    sections?.filter(
      section => section.id === parseInt(currentSectionId)
    )[0]?.cases?.length
  ])

  useEffect(() => {
    if(currentCaseId && sections.length) {
      const filteredSection = sections?.filter(
        section => section.id === parseInt(currentSectionId)
      )[0]

      const filteredCase = filteredSection?.cases?.filter(
        caseElement => caseElement.id === parseInt(currentCaseId)
      )[0]

      if (!filteredCase?.details) {
        dispatch(fetchCRMCaseDetail(
          currentSectionId,
          currentCaseId
        ));
      }
      else {
        setCurrentCase(filteredCase);
      }
    }
  }, [
    currentCaseId,
    sections.length,
    sections?.filter(
      section => section.id === parseInt(currentSectionId)
    )[0]?.cases?.length,
    sections?.filter(
      section => section.id === parseInt(currentSectionId)
    )[0]?.cases?.filter(
      caseElement => caseElement.id === parseInt(currentCaseId)
    )[0]?.details
  ])

  return {
    sections,
    currentSection,
    currentCase,
    loadingSections,
    loadingCasesList,
    loadingCaseDetail
  }

}

export default useCRM;