import { i18n } from "../config";


export const FETCH_CONTRACT_SUCCESSFUL_MSG = i18n.t("common:fetch_contract_successful_msg");
export const FETCH_CONTRACT_FAILURE_MSG = i18n.t("common:fetch_contract_failure_msg");
export const FETCH_CRM_CASE_SUCCESSFUL_MSG =  i18n.t("common:fetch_crm_case_successful_msg");
export const FETCH_CRM_CASE_FAILURE_MSG =  i18n.t("common:fetch_crm_case_failure_msg");
export const CREATE_CRM_CASE_SUCCESSFUL_MSG = i18n.t("common:create_crm_case_successful_msg");
export const CREATE_CRM_CASE_FAILURE_MSG = i18n.t("common:create_crm_case_failure_msg");
export const CREATE_CRM_CASE_MAX_FILE_SIZE = i18n.t("common:create_crm_case_max_file_size");
export const FETCH_NEWS_SUCCESSFUL_MSG = "Noticias obtenidas con éxito";
export const FETCH_NEWS_FAILURE_MSG = "Error durante la obtención de las noticias";
