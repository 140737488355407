import {
  FETCHING_CRM_CASE_LIST,
  FETCHING_CRM_CASE_DETAIL,
  FETCHING_CRM_ALL_PENDING_CASES,
  FETCHING_CRM_PENDING_CASE_DETAIL,
  FETCH_CRM_CASE_LIST,
  FETCH_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_SECTIONS,
  FETCH_CRM_CASE_CATEGORIES,
  FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  ADD_CRM_CASE_MESSAGE,
  RESET_STORE,
  SEND_NEW_CRM_CASE,
  RESPONSE_NEW_CRM_CASE,
  FETCH_CRM_ALL_PENDING_CASES,
  FETCH_CRM_PENDING_CASE_DETAIL,
  ADD_CRM_PENDING_CASE_MESSAGE,
  FETCH_CRM_PENDING_CASES,
} from "../actions/types";

import { INITIAL_CRM_STATE } from "./index";


export default (state = INITIAL_CRM_STATE, action) => {
  switch (action.type) {
    case FETCHING_CRM_CASE_LIST:
      return {
        ...state,
        loadingCasesList: true
      };
    case FETCH_CRM_CASE_LIST:
      return {
        ...state,
        message: action.payload.message,
        loadingCasesList: false,
        sections: state.sections.map(section => {
          return {
            ...section,
            ...(section.id === parseInt(action.currentSectionId) && 
              {cases: action.payload.data.items}
            )
          }
        })
      };
    case FETCHING_CRM_CASE_DETAIL:
      return {
        ...state,
        loadingCaseDetail: true
      };
    case FETCH_CRM_CASE_DETAIL:
      return {
        ...state,
        message: action.payload.message,
        loadingCaseDetail: false,
        sections: state.sections.map(section => {
          return {
            ...section,
            ...(section.id === parseInt(action.currentSectionId) &&
              {
                cases: section.cases.map(caseElement => {
                return {
                  ...caseElement,
                  ...(caseElement.id === parseInt(action.currentCaseId) &&
                    {details: action.payload.data}
                  )
                }
              })}
            )
          }
        })
      };
    case FETCHING_CRM_CASE_DETAIL:
      return {
        ...state,
        loadingSections: true
      };
    case FETCH_CRM_CASE_SECTIONS:
      return {
        ...state,
        loadingSections: false,
        sections: action.payload.data,
        categories: []
      };
    case FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST:
      return {
        ...state,
        sections_attachments: action.payload.data.data,
      };
    case CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST:
      return {
        ...state,
        sections_attachments: [],
      };
    case FETCH_CRM_CASE_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data
      };
    case ADD_CRM_CASE_MESSAGE:
      return {
        ...state,
        sections: state.sections.map(section => {
          return {
            ...section,
            ...(section.id === parseInt(action.currentSectionId) &&
              {
                cases: section.cases.map(caseElement => {
                  return {
                    ...caseElement,
                    ...(caseElement.id === parseInt(action.currentCaseId) &&
                      {details: {
                        ...caseElement.details,
                        mails: [...caseElement.details.mails, action.payload.mails],
                        attachments: [...caseElement.details.attachments, ...action.payload.attachments]
                      }}
                    )
                  }
                })
              }
            )
          }
        }),
      }
    case SEND_NEW_CRM_CASE:
      return {
        ...state,
        sendingCRMCase: action.payload.sendingCRMCase
      };
    case RESPONSE_NEW_CRM_CASE:
      return {
        ...state,
        sendingCRMCase: action.payload.sendingCRMCase
      };
    case RESET_STORE:
      return INITIAL_CRM_STATE;
    case FETCHING_CRM_ALL_PENDING_CASES:
      return {
        ...state,
        loadingPendingCasesList: true
      };
    case FETCH_CRM_ALL_PENDING_CASES:
      return {
        ...state,
        allPendingCases: action.payload.data,
        loadingPendingCasesList: false
      }
    case FETCHING_CRM_PENDING_CASE_DETAIL:
      return {
        ...state,
        loadingPendingCasesDetail: true
      };
    case FETCH_CRM_PENDING_CASE_DETAIL:
      return {
        ...state,
        allPendingCases: state.allPendingCases.map(caseElement => {
          return {
            ...caseElement,
            ...(caseElement.id === parseInt(action.caseId) &&
              {details: action.payload.data}
            )
          }
        }),
        loadingPendingCasesList: false
      }
    case ADD_CRM_PENDING_CASE_MESSAGE:
      return {
        ...state,
        allPendingCases: state.allPendingCases.map(caseElement => {
          return {
            ...caseElement,
            ...(caseElement.id === parseInt(action.currentCaseId) &&
              {details: {
                ...caseElement.details,
                mails: [...caseElement.details.mails, action.payload.mails],
                attachments: [...caseElement.details.attachments, ...action.payload.attachments]
              }}
            )
          }
        })
      }
    case FETCH_CRM_PENDING_CASES:
      return {
        ...state,
        sections: state.sections.map(section => {
          return {
            ...section,
            ...(section.id === parseInt(action.currentSectionId) &&
              {pendingCases: action.payload.data}
            )
          }
        })
      }
    default:
      return state;
  }
};