import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CRMListCase from "./components/CRMListCase";
import { InfoContainer, LoadingSkeletons } from "../../../components";
import { Fade, Table, TableBody } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { history } from '../../../config';
import { InfoContainerBody } from "../../../components/InfoContainerBody";
import { useParams } from "react-router-dom";
import NewCaseButton from "./components/NewCaseButton";
import useCRM from "../hooks/useCRM";
import { useDispatch, useSelector } from "react-redux";
import { fetchCRMPendingCases } from "../../../actions";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: 0
  }
}));

const CRMList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sectionId } = useParams();

  const {currentSection, loadingCasesList} = useCRM(sectionId);

  const pendingCases = useSelector(
    (state) => state.CRMCases?.sections?.filter(
      section => section.id === parseInt(sectionId)
    )[0]?.pendingCases ?? []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentSection) {
      dispatch(fetchCRMPendingCases(currentSection.id))
    }

  }, [currentSection])

  const hasCases = currentSection?.cases &&
    Array.isArray(currentSection.cases) &&
    currentSection.cases.length;

  const renderListCases = () => {
    if(hasCases){
      return currentSection?.cases.map((element) => (
        <CRMListCase
          key={element.id}
          caseItem={element}
          handleClick={
            () => {history.push(`/solicitudes/${sectionId}/${element.id}/`)}
          }
          isPendingCase={pendingCases.includes(element.id)}
        />
      ));
    }
    return null;
  }

  return (
    <Fade in={true}>
      <InfoContainer
        maxWidth={"lg"}
        title={currentSection?.name}
        subtitle={t("common:crm_requested_subtitle")}
        className={classes?.container}
        actionButton={!hasCases ? null : <NewCaseButton />}
        goBackArrow={'/inicio'}
      >
        { loadingCasesList ? (
          <LoadingSkeletons fullHeight/>
        ) : (
          <>
            { pendingCases.length > 0 && (
              <Alert severity="warning">
                {pendingCases.length > 1 ?
                  t("common:crm_pending_cases", {
                    pendingCasesNum: pendingCases.length
                  })
                :
                  t("common:crm_pending_case", {
                    pendingCasesNum: pendingCases.length
                  })
                }
              </Alert>
            )}
            { hasCases ? (
              <Table>
                <TableBody>
                  {renderListCases()}
                </TableBody>
              </Table>
            ) : (
              <InfoContainerBody
                maxWidth={"lg"}
                subtitle={t("common:crm_without_data")}
                className={classes.container}
                actionButton={<NewCaseButton />}
              />
            )}
          </>
        )}
      </InfoContainer>
    </Fade>
  );
}

export default CRMList;