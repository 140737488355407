import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { history } from '../../../../config';

const AttachmentsListButton = ({attachmentsNumber}) => {
  const { t } = useTranslation();
  const { sectionId, caseId } = useParams();

  return (
    <Button
      variant="contained" 
      color="primary"
      onClick={
        () => {history.push(`/solicitudes/${sectionId}/${caseId}/attachments`)}
      }
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <i className={"far fa-file"}  style={{fontSize: 16}}/>
        </Grid>
        <Grid item>
          {t('common:crm_attachment_title')} {` (${attachmentsNumber})`}
        </Grid>
      </Grid>
    </Button>
  );
}

export default AttachmentsListButton