import React from 'react';
import { Grid } from "@material-ui/core";

const CRMAlertMessage = ({id, status}) => {
  let stageColor = 'blue';

  switch(status.stage_status){
    case 'success':
    case 'closed':
      stageColor = 'green';
      break;
    case 'error':
      stageColor = 'red';
      break;
    case 'warning':
      stageColor = 'orange';
      break;
    case 'info':
    default:
      stageColor = 'blue';
      break;
  }

  return (
    <Grid item style={{color: stageColor}}>
      #{id} {status?.stage_name} - {status?.stage_description}
    </Grid>
  );
}

export default CRMAlertMessage