import React from 'react';
import { useTranslation } from "react-i18next";
import { Avatar, Box, Paper, Typography } from "@material-ui/core";
import { lighten, makeStyles, useTheme } from "@material-ui/core/styles";
import { Person } from '@material-ui/icons';
import MessageDate from './MessageDate';
import DetailRenderAttachments from './DetailRenderAttachments';
import { grey } from '@material-ui/core/colors';
import { formatDate, formatTime } from '../../utils/timeManagement';

const useStyles = makeStyles(theme => ({
  messageContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 20,
    marginLeft: 200,
    marginRight: 200,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
      marginRight: 5,
    }
  },
  messagePaper: {
    display: "inline-block",
    padding: "10px 20px",
    maxWidth: "60%",
    wordBreak: "break-word",
    position: "relative",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px",
    }
  },
  adminMessage: {
    marginRight: "auto",
    textAlign: "left",
    backgroundColor: grey[200],
    filter: "drop-shadow(-1px -1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.15))",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 10,
      left: -10,
      width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      borderRight: `10px solid ${grey[200]}`,
    },
  },
  customerMessage: {
    marginLeft: "auto",
    textAlign: "right",
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    filter: "drop-shadow(-1px -1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.15))",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 10,
      right: -10,
      width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      borderLeft: `10px solid ${lighten(theme.palette.primary.main, 0.8)}`,
    },
  },
  avatarContainer: {
    flexShrink: 0,
  },
  adminAvatarContainer: {
    marginRight: 15,
  },
  customerAvatarContainer: {
    marginLeft: 15,
  },
  adminTitle: {
    textAlign: "left",
    fontWeight: "bold",
    color: "gray",
  },
  customerTitle: {
    textAlign: "left",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  noMargin: {
    marginTop: "-10px !important",
  },
  adminNoMargin: {
    marginLeft: "255px !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "60px !important",
    }
  },
  customerNoMargin: {
    marginRight: "255px !important",
    [theme.breakpoints.down("sm")]: {
      marginRight: "60px !important",
    }
  },
  noArrow: {
    "&::before": {
      display: "none",
    },
  },
  timeStamp: {
    position: "absolute",
    bottom: 0,
    right: 10,
    color: "gray",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    }
  },
  mailMessage: {
    textAlign: "left",
    marginTop:"5px",
    marginBottom:"10px",
    fontSize: "11pt",
  },
  noMarginText: {
    marginTop: 0,
  },
  onlyAttachment: {
    marginBottom: "16px",
  },
}));

const DetailMessage = ({
  mail,
  previousMessage,
  id,
  attachments,
  date,
  currentCaseId
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const isCustomer = mail?.from?.customer ?? false;

  const sameDateAsPrevious = previousMessage &&
    formatDate(previousMessage?.date) === formatDate(date);

  const sameMessageAsPrevious = previousMessage &&
    previousMessage?.mail?.from?.customer === isCustomer &&
    sameDateAsPrevious;

  return (
    <>
      {!sameDateAsPrevious && (
        <MessageDate date={date} id={id} />
      )}
      <Box
        className={`
          ${classes.messageContainer}
          ${sameMessageAsPrevious ? classes.noMargin : ""}
          ${sameMessageAsPrevious ?
            isCustomer ?
              classes.customerNoMargin
            : classes.adminNoMargin
          : ""}
        `}
        style={{ flexDirection: isCustomer ? "row-reverse" : "row" }}
      >
        {!sameMessageAsPrevious && (
          <Box
            className={`
              ${classes.avatarContainer}
              ${isCustomer ?
                classes.customerAvatarContainer
              : classes.adminAvatarContainer}
            `}
          >
            <Avatar
              style={{
                backgroundColor: isCustomer ?
                  theme.palette.primary.main
                : grey[600]
              }}
            >
              {isCustomer ? mail?.from?.name?.[0] : <Person />}
            </Avatar>
          </Box>
        )}
        <Paper
          elevation={0}
          key={id}
          className={`
            ${classes.messagePaper}
            ${isCustomer ? classes.customerMessage : classes.adminMessage}
            ${sameMessageAsPrevious ? classes.noArrow : ""}
          `}
        >
          {!sameMessageAsPrevious && (
            <Typography
              className={`
                ${isCustomer ? classes.customerTitle : classes.adminTitle}`
              }
            >
              {isCustomer ? mail?.from?.name : t("common:administrator")}
            </Typography>
          )}
          { attachments.length > 0 && (
            <DetailRenderAttachments
              attachments={attachments}
              currentCaseId={currentCaseId}
            />
          )}
          <Typography
            className={`
              ${classes.mailMessage}
              ${sameMessageAsPrevious ? classes.noMarginText : ""}
              ${!mail?.message ? classes.onlyAttachment: ""}
            `}
          >
            {mail?.message}
          </Typography>
          <Typography className={classes.timeStamp}>
            {formatTime(date)}
          </Typography>
        </Paper>
      </Box>
    </>
  );
}

export default DetailMessage;
