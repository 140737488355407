import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { sendNewCRMCaseMessage, sendNewCRMPendingCaseMessage } from '../../../../actions';
import SendIcon from '@material-ui/icons/Send';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputBase,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MultiUploadButton from "../../components/MultiUploadButton";
import { Alert } from "@material-ui/lab";
import ShowAddedAttachments from "../../components/ShowAddedAttachments";
import { grey } from '@material-ui/core/colors';

const NewComment = ({fromPending=false}) => {
  const { t } = useTranslation();
  const { 
    sectionId,
    caseId
  } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [displayMessage, setMessage] = useState('');
  const [displaySendingMessage, setSendingMessage] = useState(false);
  const [displayErrorMessage, setErrorMessage] = useState('');
  const [displayErrorSending, setErrorSending] = useState(false);
  const [displayAttachments, setAttachments] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!displayMessage || (displayMessage && displayMessage.length < 10)){
      setErrorMessage(t("common:crm_error_message_characters"));
      setErrorSending(true);
    } else {
      setSendingMessage(true);
      setErrorSending(false);

      if (fromPending) {
        dispatch(sendNewCRMPendingCaseMessage(
          caseId, 
          { 
            'message': displayMessage,
            'attachments': displayAttachments
          }
        ))
        .then(
          response => {
            if(response[0] !== 200) {
              setErrorSending(true);
              setErrorMessage(t("common:crm_error_message"));
              setSendingMessage(false);
            }
            else {
              setErrorSending(false);
              setErrorMessage('');
              setSendingMessage(false);
              setMessage('');
              setAttachments([]);
            }
          }
        );
      }
      else {
        dispatch(sendNewCRMCaseMessage(
          sectionId,
          caseId, 
          { 
            'message': displayMessage,
            'attachments': displayAttachments
          }
        ))
        .then(
          response => {
            if(response[0] !== 200) {
              setErrorSending(true);
              setErrorMessage(t("common:crm_error_message"));
              setSendingMessage(false);
            }
            else {
              setErrorSending(false);
              setErrorMessage('');
              setSendingMessage(false);
              setMessage('');
              setAttachments([]);
            }
          }
        );
      }
    }
  }

  const handleUpload = (file) => {
    const uploadsArr = [...displayAttachments];
    uploadsArr.push(file);
    const attachmentsList = uploadsArr.filter((file) => file && file.name);
    setAttachments(attachmentsList);
  }

  const handleRemoveFile = (position) => {
    const uploadsArr = [...displayAttachments];
    const attachmentsList = uploadsArr.filter((file, idx) => idx !== position);
    setAttachments(attachmentsList);
  };

  const handleMessage = event => {
    setMessage(event.target.value);
  }
 
  return( 
    <Paper
      component="form"
      onSubmit={handleSubmit}
      elevation={0}
      style={{
        margin: `20px ${isMobile ? "4px" : "0px"}`
      }}
    >
      {displayErrorSending &&
        <Alert
          severity="error"
          style={{
            marginBottom: "4px",
          }}
        >
          {displayErrorMessage}
        </Alert>
      }
      <Grid
        container
        alignItems="flex-start"
        style={{
          border: `1px solid ${grey[300]}`,
          borderRadius: "10px",
          padding: "4px",
          paddingBottom: 0,
        }}
      >
        <Grid
          container
          item
          md={10}
          xs={9}
        >
          <ShowAddedAttachments
            attachments={displayAttachments}
            maxAttachmentLength={20}
            handleRemoveFile={handleRemoveFile}
          />
          <Grid item md={1} xs={2}>
            <MultiUploadButton
              attachmentsLength={displayAttachments.length}
              max={3}
              onChange={handleUpload}
              fromCreate={false}
            />
          </Grid>
          <Grid item md={11} xs={10} style={{display: "flex", alignItems: "center"}}>
            <InputBase
              placeholder="Respuesta"
              multiline
              rowsMax={Infinity}
              disabled={displaySendingMessage}
              value={displayMessage}
              onChange={handleMessage}
              style={{width: "100%"}}
            />
          </Grid>
        </Grid>
        <Grid
          item
          md={2}
          xs={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "4px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={displaySendingMessage}
            type="submit"
            style={{
              fontSize: isMobile ? "9pt" : undefined
            }}
            endIcon={displaySendingMessage ?
              <CircularProgress
                style={{
                  fontSize: isMobile ? 16 : 24,
                  width: isMobile ? 16 : undefined,
                  height: isMobile ? 16 : undefined,
                }}
              />
            :
              <SendIcon
                style={{fontSize: isMobile ? 16 : 24}}
              />
            }
          >
            { displaySendingMessage ?
              t("common:sending")
            :
              t("common:send")
            }
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default NewComment;