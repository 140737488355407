import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { InfoContainer } from "../../components";
import Fade from "@material-ui/core/Fade";
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MailIcon from '@material-ui/icons/Mail';
import EcoIcon from '@material-ui/icons/Eco';
import ErrorIcon from '@material-ui/icons/Error';
import EvStationIcon from '@material-ui/icons/EvStation';
import { history, theme } from '../../config';
import Settings from "../../settings.yml";
import useCRM from "../CRM/hooks/useCRM";
import { useDispatch, useSelector } from "react-redux";
import { fetchCRMAllPendingCases } from "../../actions";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: '20px',
    verticalAlign: 'center',
  },
  '@keyframes appButtonSelected': {
    from: {backgroundColor: theme.palette.primary.main},
    to: {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: `0 0 5px ${theme.palette.primary.main}`
    }
  },
  appButton: {
    minHeight: '20vh',
    minWidth: '20vh', 
    textAlign: 'center', 
    padding: '10px', 
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.primary.contrastText,
    '&:hover': {
      animation: '$appButtonSelected 0.5s forwards',
      cursor: 'pointer',
    }
  }
}));

const AppButton = ({name, icon, path, theme, state}) => {
  return(
    <Paper className={theme} onClick={() => {history.push(path, state)}}>
      <div>
        {icon}
      </div>
      <div>
        {Array.isArray(name) ? 
          name.map(val => 
            <Typography
              key={val}
              variant="h5" 
              style={{userSelect: 'none', fontWeight: '450'}}
            >
              {val}
            </Typography>
          )
        :
          <Typography
            variant="h5"
            style={{userSelect: 'none', fontWeight: '450'}}
          >
            {name}
          </Typography>
        }
      </div>
    </Paper>
  )
};

const CRMViewsIcon = (code) => {
  switch (code) {
    case 'AUTOCONS':
      return <EcoIcon style={{ fontSize: 80 }}/>
    case 'RECLAMACIO':
      return <ErrorIcon style={{ fontSize: 80 }}/>
    case 'EVEHICLE':
      return <EvStationIcon style={{ fontSize: 80 }}/>
    case 'SOL_CRM':
    default:
      return <MailIcon style={{ fontSize: 80 }}/>
  }
}

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allPendingCases = useSelector(
    (state) => state.CRMCases?.allPendingCases
  );

  useEffect(() => {
    dispatch(fetchCRMAllPendingCases())
  }, [])

  const {sections} = useCRM();

  const getCRMViews = () => {
    return (sections.map((element) =>
      <Grid item key={element.id}>
        <AppButton 
          name={element.name}
          path={`/solicitudes/${element.id}`}
          icon={element.ov_icon_svg ?
              <svg
                height="80px"
                viewBox={element.ov_icon_svg.match(/viewBox="([^"]*)"/)?.[1]}
                width="80px"
              >
                <path
                  fill={theme.palette.primary.contrastText}
                  d={element.ov_icon_svg.match(/<path d="([^"]*)"/)?.[1]}
                />
              </svg>
            :
              element.code ?
                CRMViewsIcon(element.code)
              :
                undefined
          }
          theme={classes.appButton}
        />
      </Grid>
    ))
  }

  const renderAllPendingCases = () => {
    return (
      <Grid item>
        <AppButton
          name={t('common:crm_pending_cases_title')}
          path="/pendientes"
          icon={<AccessTimeIcon style={{ fontSize: 80 }}/>}
          theme={classes.appButton}
        />
      </Grid>
    )
  }

  return (
    <Fade in={true}>
      <InfoContainer
        maxWidth={"lg"}
        className={classes.container}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <AppButton 
              name={[
                t('common:dash_contracts_plural'),
                t('common:dash_measurements_plural')
              ]}
              path="/contratos"
              icon={<PersonIcon style={{ fontSize: 80 }}/>}
              theme={classes.appButton}
            />
          </Grid>
          { Settings.crm_enabled && allPendingCases?.length > 0 &&
            renderAllPendingCases()
          }
          {Settings.crm_enabled &&
            getCRMViews()
          }
        </Grid>
      </InfoContainer>
    </Fade>
  )
}

export default Home;