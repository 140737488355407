import React from 'react';
import { Chip, Grid, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';

const ShowAddedAttachments = ({
  attachments=[],
  maxAttachmentLength=20,
  handleRemoveFile
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return ( !!attachments.length &&
    <Grid
      item
      container
      md={12}
      spacing={1}
    >
      {attachments.map((attachment, idx) => (
        <Grid item>
          <Tooltip title={attachment.name} arrow>
            <Chip
              key={idx}
              label={attachment.name?.length > maxAttachmentLength 
                ? `${attachment.name.substring(0, maxAttachmentLength)}...` 
                : attachment.name}
              onDelete={() => handleRemoveFile(idx)}
              color="primary"
              variant="outlined"
            />
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
}

export default ShowAddedAttachments