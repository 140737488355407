import React from "react";
import { Alert, AlertTitle } from '@material-ui/lab';

const StatusIndicator = ({status}) => {

  let severityAlert = 'info';

  switch(status.stage_status){
    case 'success':
    case 'closed':
      severityAlert = 'success';
      break;
    case 'error':
      severityAlert = 'error';
      break;
    case 'warning':
      severityAlert = 'warning';
      break;
    case 'info':
    default:
      severityAlert= 'info';
      break;
  }

  return (
    <div>
      <Alert severity={severityAlert}>
        <AlertTitle>{status?.stage_name}</AlertTitle>
        {status?.stage_description}
      </Alert>
    </div>
  );
}

export default StatusIndicator;