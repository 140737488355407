import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StatusIndicator from "./components/StatusIndicator";
import CRMTitle from "../components/CRMTitle";
import { InfoContainer, LoadingSkeletons } from "../../../components";
import DetailConversation from "./components/DetailConversation";
import DetailAnswerBox from "./components/DetailAnswerBox";
import useCRM from "../hooks/useCRM";
import AttachmentsListButton from "./components/AttachmentsListButton";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: 0
  }
}));

const CRMDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { 
    sectionId,
    caseId
  } = useParams();
  const {loadingCaseDetail, currentCase} = useCRM(sectionId, caseId);
  const hasAttachments = currentCase?.details?.attachments.length

  return (currentCase ?
    <Fade in={true}>
      <InfoContainer
        maxWidth={"lg"}
        title={<CRMTitle currentCase={currentCase} />}
        subtitle={t("common:crm_detail_subtitle")}
        className={classes.container}
        goBackArrow={`/solicitudes/${sectionId}`}
        actionButton={hasAttachments ?
            <AttachmentsListButton attachmentsNumber={hasAttachments}/>
          :
            null
        }
      >
        <Box>
          <StatusIndicator status={currentCase?.status}/>
          <Box mt={2} style={{position: "relative"}}>
            <DetailConversation currentCase={currentCase} />
          </Box>
          <DetailAnswerBox currentCase={currentCase} fromPending={false}/>
        </Box>
      </InfoContainer>
    </Fade>
  :
    <Fade in={true}>
      <Box>
        {loadingCaseDetail &&
          <LoadingSkeletons fullHeight/>
        }
      </Box>
    </Fade>
  );
}

export default CRMDetail;
