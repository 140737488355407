export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
export const LOGOUT = 'USERS_LOGOUT';
export const LOCALE_CHANGE = 'USERS_LOCALE_CHANGE';
export const USER_PROFILE_RECEIVE = 'USER_PROFILE_RECEIVE';

export const RESET_STORE = 'RESET_STORE';
export const FETCH_CONTRACT_LIST = 'FETCH_CONTRACT_LIST';
export const FETCH_CONTRACT = 'FETCH_CONTRACT';
export const FETCHING_CRM_CASE_SECTIONS = 'FETCHING_CRM_CASE_SECTIONS';
export const FETCHING_CRM_CASE_LIST = 'FETCHING_CRM_CASE_LIST';
export const FETCHING_CRM_CASE_DETAIL = 'FETCHING_CRM_CASE_DETAIL';
export const FETCHING_CRM_ALL_PENDING_CASES = 'FETCHING_CRM_ALL_PENDING_CASES';
export const FETCHING_CRM_PENDING_CASE_DETAIL = 'FETCHING_CRM_PENDING_CASE_DETAIL';
export const FETCH_CRM_CASE_LIST = 'FETCH_CRM_CASE_LIST';
export const FETCH_CRM_CASE_DETAIL = 'FETCH_CRM_CASE_DETAIL';
export const FETCH_CRM_CASE_SECTIONS = 'FETCH_CRM_CASE_SECTIONS';
export const FETCH_CRM_CASE_CATEGORIES = 'FETCH_CRM_CASE_CATEGORIES';
export const FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST = 'FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST';
export const CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST = 'CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST';
export const FETCH_CRM_ALL_PENDING_CASES = 'FETCH_CRM_ALL_PENDING_CASES';
export const FETCH_CRM_PENDING_CASE_DETAIL = 'FETCH_CRM_PENDING_CASE_DETAIL';
export const FETCH_CRM_PENDING_CASES = 'FETCH_CRM_PENDING_CASES';

export const ADD_CRM_CASE_MESSAGE = 'ADD_CRM_CASE_MESSAGE';
export const ADD_CRM_PENDING_CASE_MESSAGE = 'ADD_CRM_PENDING_CASE_MESSAGE';

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';
export const SEND_NEW_CRM_CASE = 'SEND_NEW_CRM_CASE';
export const RESPONSE_NEW_CRM_CASE = 'RESPONSE_NEW_CRM_CASE';

export const FETCH_NEWS = 'FETCH_NEWS';
export const FETCHING_NEWS = 'FETCHING_NEWS';