import React from "react";
import { darken, Grid, TableCell, TableRow, useTheme} from '@material-ui/core';
import CRMTitle from "../../components/CRMTitle";
import CRMSubtitle from "../../components/CRMSubtitle";
import { useTranslation } from "react-i18next";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const CRMListCase = ({ caseItem, handleClick, isPendingCase=false }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <TableRow hover>
      <TableCell
        key={caseItem?.contract}
        style={{
          cursor: 'pointer'
        }}
        onClick={handleClick}
      >
        {isPendingCase &&
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{color: theme.palette.warning.main}}>
              <ReportProblemOutlinedIcon />
            </Grid>
            <Grid item style={{color: darken(theme.palette.warning.main, 0.4)}}>
              {t("common:crm_current_pending_cases")}
            </Grid>
          </Grid>
        }
        <Grid container spacing={0}>
          <CRMTitle currentCase={caseItem} />
          <CRMSubtitle
            id={caseItem?.referencia_externa}
            status={caseItem?.status}
          />
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default CRMListCase;