import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Tooltip } from "@material-ui/core";
import DetailMessage from './DetailMessage';
import MessageDate from './MessageDate';
import { useSelector } from 'react-redux';

const DetailConversation = ({currentCase}) => {
  const mails = currentCase?.details?.mails;
  const attachments = currentCase?.details?.attachments;
  const scrollContainerRef = useRef(null);

  const userName = useSelector((state) => state?.user?.name);

  const [tooltipMessage, setTooltipMessage] = useState(null);
  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (mails && attachments){
      setMessages(contructMessages(mails, attachments));
    }
  }, [mails.length, attachments.length])


  useEffect(() => {
    if (messages) {
      const element = document.getElementById(`message-${messages.length-1}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [messages.length]);

  const checkOverflow = (event) => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const dateBoxes = scrollContainer.querySelectorAll('[id*="dateBox"]');
    let tooltipMessageFound = null;

    dateBoxes.forEach(dateBox => {
      const rect = dateBox.getBoundingClientRect();
      const containerRect = scrollContainer.getBoundingClientRect();
      
      if (rect.bottom < containerRect.top) {
        const messageId = dateBox.id.split('dateBox-')[1]
        tooltipMessageFound = messages[messageId];
      }
    });

    if (!tooltipMessageFound) {
      setTooltipMessage(null);
    } else {
      setTooltipMessage(tooltipMessageFound);
    }
  }

  const contructMessages = (mails, attachments) => {
    const messagesMap = new Map();

    
    mails.length && mails.forEach(mail => {
      const key = mail.date;
      if (!messagesMap.has(key)) {
        messagesMap.set(key, { attachments: [], mail, date: key });
      } else {
        messagesMap.get(key).mail = mail;
      }
    });

    attachments.length && attachments.forEach(attachment => {
      const key = attachment.date;
      if (!messagesMap.has(key)) {
        messagesMap.set(
          key,
          {
            attachments: [attachment],
            mail: {
              from: {
                customer: attachment.creator === 'ov',
                name: attachment.creator === 'ov' ? userName : ''
              }
            },
            date: key
          }
        );
      } else {
        messagesMap.get(key).attachments.push(attachment);
      }
    });

    const messagesArray = Array.from(messagesMap.values()).sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    return messagesArray;
  }

  return (
    <>
      { tooltipMessage &&
        <Box
          style={{
            position: "absolute",
            zIndex: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <Tooltip open={true}>
            <MessageDate date={tooltipMessage?.date} id={"tooltip"} />
          </Tooltip>
        </Box>
      }
      <Box
        ref={scrollContainerRef}
        onScroll={checkOverflow}
        maxHeight={550}
        style={{overflowY: "scroll"}}
      >
        <Grid
          container
          direction="row"
          style={{width: "100%"}}
        >
          {messages.map((message, index) => (
            <Grid
              key={index}
              item
              xs={12}
              justify={message?.mail?.from?.customer ? "flex-end" : "flex-start"}
              id={`message-${index}`}
              style={{marginTop: "8px", marginBottom: "8px"}}
            >
              <DetailMessage
                mail={message?.mail}
                previousMessage={index > 0 ? messages[index - 1] : null}
                id={index}
                attachments={message?.attachments}
                date={message?.date}
                currentCaseId={currentCase.id}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
)
}

export default DetailConversation