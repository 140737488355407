import React from 'react';
import { Grid } from "@material-ui/core";
import NewComment from './NewComment';

const DetailAnswerBox = ({currentCase, fromPending=false}) => {
  return currentCase?.status?.state !== "done" && (
    <Grid
      container 
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Grid item md={1} xs={false}/>
      <Grid item md={10} xs={12}>
        <NewComment fromPending={fromPending}/>
      </Grid>
      <Grid item md={1} xs={false}/>
    </Grid>
  );
}

export default DetailAnswerBox