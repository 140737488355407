import common_ca from './ca/common.json';
import common_es from './es/common.json';
import validation_ca from './ca/validation.json';
import validation_es from './es/validation.json';
import custom_ca from './ca/custom.json';
import custom_es from './es/custom.json';
import jwt_decode from 'jwt-decode';
import { store } from '../config';
import _ from 'lodash';
import Settings from "../settings.yml";


const updateTranslationsWithCustom = () => {
  _.merge(common_ca, custom_ca);
  _.merge(validation_ca, custom_ca);
  _.merge(common_es, custom_es);
  _.merge(validation_es, custom_es);
}

updateTranslationsWithCustom();

export const resources = {
  ca: {
    common: common_ca,
    validation: validation_ca,
  },
  es: {
    common: common_es,
    validation: validation_es,
  },
};

const getLangCode = (locale) => {
  return locale.indexOf('-') > 0 ? locale.split('-')[0]
    : locale.indexOf('_') > 0 ? locale.split('_')[0]
    : locale
}

export const getLangByToken = (token) => {
  try {
    const token_values = jwt_decode(token);
    return getLangCode(token_values.locale);
  } catch (e) {
    console.debug('ERROR', e);
    return null;
  }
}

export const getLangByBrowser = () => {
  try {
    return getLangCode(navigator.language);
  } catch (e) {
    console.debug('ERROR getting browser language');
    return null;
  }
}

export const getLang = () => {
  const token = store && _.get(store.getState(), 'user.token', null);
  if (token) {
    console.debug('Getting lang by token');
    return getLangByToken(token);
  }
  else {
    return Settings.defaultLanguage;
  }
}
