import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Button,
  Chip,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@material-ui/lab';
import saveAs from 'file-saver';

const RequirementDoc = ({
  downloadFunction,
  attachmentId=null,
  fileLabel='Document',
  required=false,
  handleUploaded,
  index=0,
  downloadLabel='Download',
  styleProps,
  value
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [displayFile, setFile] = useState(value);
  const [displayFilled, setFilled] = useState(false);

  const handleOnFileLoad = (e) => {
    let reader = new FileReader();
    const targetFile = e.target.files[0];
    reader.onloadend = () => {
      const file = {
        name: targetFile.name,
        content: reader.result,
        index: index,
      };
      setFile(file);
      setFilled(true);
      if (handleUploaded) {
        handleUploaded(
          file,
          index,
          required === 'must_fill'
        );
      }
    }
    if (targetFile) {
      reader.readAsDataURL(targetFile);
    }
  };

  const handleClearFile = () => {
    setFile(null);
    setFilled(false);
    if (handleUploaded) {
      handleUploaded(
        null,
        index,
        required === 'must_fill'
      );
    }
  };

  const handleDownload = () => {
    saveAs(displayFile.content, displayFile.name);
  };

  const uploadFile = () => {
    const maxDisplayFileLength = 12;
    return (
      <>
        {displayFilled ?
          <Tooltip
            title={displayFile.name.length > maxDisplayFileLength &&
              displayFile.name
            }
            aria-label="displayed-file"
          >
            <Button
              variant="outlined"
              endIcon={<DeleteIcon />}
              size="small"
              onClick={handleClearFile}
              style={{
                borderColor: theme.palette.error.main,
                color: theme.palette.error.main,
                width: "80%"
              }}
            >
              {displayFile.name.length > maxDisplayFileLength ?
                `${displayFile.name.substring(0, maxDisplayFileLength)}...`
              :
                displayFile.name
              }
            </Button>
          </Tooltip>
        :
          <>
            <input
              type="file"
              style={{display: "none"}}
              id={`upload-attachment-${attachmentId}`}
              onChange={(event) => handleOnFileLoad(event)}
            />
            <label htmlFor={`upload-attachment-${attachmentId}`}>
              <Button
                component="span"
                variant="contained"
                color="primary"
                startIcon={<PublishIcon />}
                size="small"
                style={{width: "80%"}}
              >
                {t("common:upload", {what: t('common:file').toLowerCase()})}
              </Button>
            </label>
          </>
          }
      </>
    )
  }

  return (
    <Paper
      style={
          {...styleProps, ...{textAlign:'center', minHeight: "inherit"}}
        }
      onClick={() => {}}
    >
      <Grid
        container
        alignItems="center"
        style={{minHeight: "inherit"}}
      >
        <Grid item md={1} style={{textAlign: 'left'}}>
          <DescriptionIcon style={{ fontSize: "32px", margin: "4px 12px" }}/>
        </Grid>
        <Grid item md={7} style={{textAlign: 'left'}}>
          <Typography
            variant="subtitle2"
            style={{fontWeight: '450'}}
          >
            {fileLabel}
          </Typography>
        </Grid>
        <Grid item md={2}>
          {required !== 'information' &&
            uploadFile()
          }
        </Grid>
        <Grid item md={2} style={{textAlign: 'right'}}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<GetAppIcon />}
            size="small"
            onClick={() => {downloadFunction(attachmentId)}}
            style={{marginRight: "8px"}}
          >
            {downloadLabel}
          </Button>
        </Grid>
      </Grid>
      {required === 'must_fill' && !displayFilled &&
        <Grid item xs={12} style={{paddingBottom: 0}}>
          <Alert severity="error">
            {t("common:must_fill_in")}
          </Alert>
        </Grid>
      }
    </Paper>
  );
}

export default RequirementDoc;
