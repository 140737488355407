
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, CircularProgress, Container, makeStyles, Typography } from "@material-ui/core";
import { Event as EventIcon } from "@material-ui/icons";
import { getLang } from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: theme.spacing(2),
  },
  content: {
    width: "100%",
    maxWidth: 800,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    width: "100%",
    marginBottom: theme.spacing(3),
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[4],
    },
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  date: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    fontSize: "12pt",
    marginBottom: theme.spacing(2),
  },
  dateIcon: {
    fontSize: "12pt",
    marginRight: theme.spacing(1),
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
}))

const NewsComponent = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const news = useSelector((state) => state.news)

  useEffect(() => {
    if (news?.items?.length == 0){
      dispatch(fetchNews())
    }
  }, [])

  return (
    <Container className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" align="center">
          {t("common:news")}
        </Typography>
        <Typography
          color="textSecondary"
          gutterBottom
          className={classes.subtitle}
        >
          {t("common:last_news")}
        </Typography>

        {news?.loadingNews ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : news?.items ? (
          news.items.map((item, index) => (
            <Card key={index} className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.date}>
                  <EventIcon className={classes.dateIcon} />
                  {new Date(item.news_date).toLocaleDateString(getLang(), { day: '2-digit', month: 'long', year: 'numeric' })}
                </Typography>
                <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.content }} />
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography align="center">{t("common:no_news")}</Typography>
        )}
      </div>
    </Container>
  )
}

export default NewsComponent

