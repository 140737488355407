import React, { useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { i18n } from '../../../../config';
import { organization } from '../../../../config';
import { LinkRouter } from '../../../../components';
import Settings from "../../../../settings.yml";
import { useDispatch, useSelector } from "react-redux";
import { fetchNews } from "../../../../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  urlCenter: {
    textAlign: 'center',
    display: 'block',
  },
  footerTitle: {
    textAlign: 'center',
    marginTop: 'auto',
    color: theme.palette.grey[500],
  },
}));

const Footer = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const news = useSelector((state) => state.news)

  useEffect(() => {
      dispatch(fetchNews())
  }, [])

  return (
    <Box
      {...rest}
      className={clsx(className, classes.root)}
      borderTop={1}
      borderColor="grey.200"
    >
      {news?.items?.length > 0 &&
        <LinkRouter to="/noticias" variant="body1" className={classes.urlCenter}>
          {i18n.t('common:news')}
        </LinkRouter>
      }
      <Typography variant="h6" className={classes.footerTitle}>
        {organization.name}
      </Typography>
    </Box>
  );
};

export default Footer;
