import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import CRMTitle from "../../components/CRMTitle";
import { InfoContainer, LoadingSkeletons } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchCRMAllPendingCases } from "../../../../actions";
import { fetchCRMPendingCaseDetail } from "../../../../actions/CRMActions";
import DetailRenderAttachments from "../../Detail/components/DetailRenderAttachments";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: 0
  }
}));

const PendingCasesAttachmentList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { caseId} = useParams();

  const dispatch = useDispatch();

  const allPendingCases = useSelector(
    (state) => state?.CRMCases?.allPendingCases
  );

  const currentCase = useSelector(
    (state) => state?.CRMCases?.allPendingCases?.filter(
      caseElement => caseElement.id === parseInt(caseId)
    )[0]
  );

  const loadingPendingCasesList = useSelector(
    (state) => state?.CRMCases?.loadingPendingCasesList
  );
  
  useEffect(() => {
    if (!allPendingCases?.length) {
      dispatch(fetchCRMAllPendingCases());
    }
    else {
      dispatch(fetchCRMPendingCaseDetail(caseId));
    }
  }, [allPendingCases?.length])

  const attachments = currentCase?.details?.attachments;

  return (
    <Fade in={true}>
      <InfoContainer
        maxWidth={"lg"}
        title={<CRMTitle currentCase={currentCase} />}
        subtitle={t("common:crm_attachment_subtitle")}
        className={classes?.container}
        goBackArrow={`/pendientes/${caseId}`}
      >
        { loadingPendingCasesList || !attachments ?
          <LoadingSkeletons fullHeight/>
        : 
          <DetailRenderAttachments
            attachments={attachments}
            currentCaseId={caseId}
            isAttachmentsList={true}
          />
        }
      </InfoContainer>
    </Fade>
  );
}

export default PendingCasesAttachmentList;