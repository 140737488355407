import apiWrapper from "./AxiosRequestsClass";
import { parseResponse } from "./utils";


export default class NewsService {

  constructor(config) {
    this.config = config;
  }

  getNews = () => {
    return apiWrapper.get(`/news/`, {}, '/v2');
  };
}


