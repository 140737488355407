import CRMService from "../service/CRMService";
import {
  FETCHING_CRM_CASE_SECTIONS,
  FETCHING_CRM_CASE_LIST,
  FETCHING_CRM_CASE_DETAIL,
  FETCHING_CRM_ALL_PENDING_CASES,
  FETCHING_CRM_PENDING_CASE_DETAIL,
  FETCH_CRM_CASE_LIST,
  FETCH_CRM_CASE_DETAIL,
  FETCH_CRM_CASE_SECTIONS,
  FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  FETCH_CRM_CASE_CATEGORIES,
  CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST,
  ADD_CRM_CASE_MESSAGE,
  SEND_NEW_CRM_CASE,
  RESPONSE_NEW_CRM_CASE,
  FETCH_CRM_ALL_PENDING_CASES,
  FETCH_CRM_PENDING_CASE_DETAIL,
  ADD_CRM_PENDING_CASE_MESSAGE,
  FETCH_CRM_PENDING_CASES,
} from "./types";

import {
  FETCH_CRM_CASE_SUCCESSFUL_MSG,
  FETCH_CRM_CASE_FAILURE_MSG,
  CREATE_CRM_CASE_SUCCESSFUL_MSG,
  CREATE_CRM_CASE_FAILURE_MSG,
  CREATE_CRM_CASE_MAX_FILE_SIZE,
} from './messages';
import { parseResponse } from "../service";
import {openGlobalSnackbar} from "./globalActions";

export const actionSuccessfulCRMCasesFetch = (actionType, data, currentSectionId) => {
  return {
    type: actionType,
    payload: {
      data,
      message: FETCH_CRM_CASE_SUCCESSFUL_MSG
    },
    currentSectionId
  }
};

export const actionFailedCRMCasesFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: FETCH_CRM_CASE_FAILURE_MSG
    }
  }
};

export const actionSuccessfulCRMCaseDetailFetch = (actionType, data, currentSectionId, currentCaseId) => {
  return {
    type: actionType,
    payload: {
      data: data,
      message: FETCH_CRM_CASE_SUCCESSFUL_MSG
    },
    currentSectionId,
    currentCaseId
  }
};

export const actionFailedCRMCaseDetailFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: FETCH_CRM_CASE_FAILURE_MSG
    }
  }
};


export const fetchCRMCases = (currentSectionId) => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_CRM_CASE_LIST
  });
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCasesList(currentSectionId)
  .then(
    response => {
      dispatch(
        actionSuccessfulCRMCasesFetch(FETCH_CRM_CASE_LIST, response?.data, currentSectionId)
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedCRMCasesFetch(FETCH_CRM_CASE_LIST));
      return parseResponse(error);
    }
  )
};

export const fetchCRMCaseDetail = (currentSectionId, currentCaseId) => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_CRM_CASE_DETAIL
  });
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDetail(currentCaseId)
  .then(
    response => {
      // Order case mails by date
      let data = response?.data || [];
      if(Object.keys(data).length > 0 && data?.mails?.length > 1 ){
        data.mails = data.mails.sort((a,b) => {
          return new Date(a.date) - new Date(b.date);
        })
      }
      dispatch(
        actionSuccessfulCRMCaseDetailFetch(FETCH_CRM_CASE_DETAIL, data, currentSectionId, currentCaseId)
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedCRMCaseDetailFetch(FETCH_CRM_CASE_DETAIL));
      return parseResponse(error);
    }
  )
};

export const fetchCRMCaseSections = () => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_CRM_CASE_SECTIONS
  });
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseSections()
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_SECTIONS,
        payload: {
          data: response.data
        }
      });
    }
  )
}

export const fetchCRMCaseCategories = (ids) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseCategories(ids)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_CATEGORIES,
        payload: {
          data: response.data
        }
      });
    }
  )
}

export const fetchCRMCaseSectionsAttachmentsList = (id) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseSectionAttachmentsList(id)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_CASE_SECTION_ATTACHMENTS_LIST,
        payload: {
          data: response
        }
      });
    }
  )
}

export const cleanSectionsAttachmentsList = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_CRM_CASE_SECTION_ATTACHMENTS_LIST,
    payload: {}
  });
}

export const downloadCRMCaseSectionsAttachment = (sectionAttachmentId) => async () => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDownloadSectionAttachment(sectionAttachmentId)
  .then(
    response => {
      return parseResponse(response);
    }
  )
}

export const actionSuccessfulNewCRMCaseCreation = (actionType, data) => {
  return {
    type: actionType,
    payload: {
      data: data,
      message: CREATE_CRM_CASE_SUCCESSFUL_MSG,
      sendingCRMCase: false,
    }
  }
};

export const actionFailedNewCRMCaseCreation = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: CREATE_CRM_CASE_FAILURE_MSG,
      sendingCRMCase: false,
    }
  }
};

export const sendNewCRMCase = data => async (dispatch) => {
  const _CRMService = new CRMService();
  dispatch({
    type: SEND_NEW_CRM_CASE,
    payload: {
      sendingCRMCase: true,
    }
  });
  return await _CRMService.postCRMCase(data)
  .then(
    response => {
      dispatch(
          openGlobalSnackbar(response?.response?.data?.message ?? CREATE_CRM_CASE_SUCCESSFUL_MSG, false)
      );
      dispatch(
        actionSuccessfulNewCRMCaseCreation(RESPONSE_NEW_CRM_CASE, response?.data ?? [])
      );
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      if (error?.response?.status === 413) {
        dispatch(
          openGlobalSnackbar(CREATE_CRM_CASE_MAX_FILE_SIZE, true)
        );
      } else{
        dispatch(
          openGlobalSnackbar(error?.response?.data?.message ?? CREATE_CRM_CASE_FAILURE_MSG, true)
        );
      }
      dispatch(
        actionFailedNewCRMCaseCreation(RESPONSE_NEW_CRM_CASE)
      );
      return parseResponse(error);
    }
  );
}

export const sendNewCRMCaseMessage = (currentSectionId, currentCaseId, data) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.postCRMCaseMessage(currentCaseId, data)
  .then(
    response => {
      const d = new Date();
      const date = d.getHours() + ":" + d.getMinutes() 
        + ":" + d.getSeconds() + " " + d.getDay()
        + "/" + d.getMonth() + "/" + d.getFullYear()
      dispatch({
        type: ADD_CRM_CASE_MESSAGE,
        payload: response.data,
        currentSectionId,
        currentCaseId
      });
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      return parseResponse(error);
    }
  );
}

export const downloadAttachment = (caseId, attachmentId) => async () => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDownloadAttachment(caseId, attachmentId)
  .then(
    response => {
      return parseResponse(response);
    }
  ).catch(
    error => {
      return parseResponse(error);
    }
  )
}

export const fetchCRMAllPendingCases = () => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_CRM_ALL_PENDING_CASES
  });
  const _CRMService = new CRMService();
  return await _CRMService.getCRMAllPendingCases()
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_ALL_PENDING_CASES,
        payload: {
          data: response.data
        }
      });
    }
  ).catch(
    error => {
      dispatch(
        actionFailedCRMPendingCaseDetailFetch(
          FETCH_CRM_PENDING_CASE_DETAIL
        )
      );
      return parseResponse(error);
    }
  )
}

export const actionSuccessfulCRMPendingCaseDetailFetch = (actionType, data, caseId) => {
  return {
    type: actionType,
    payload: {
      data: data,
      message: FETCH_CRM_CASE_SUCCESSFUL_MSG
    },
    caseId
  }
};

export const actionFailedCRMPendingCaseDetailFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: FETCH_CRM_CASE_FAILURE_MSG
    }
  }
};

export const fetchCRMPendingCaseDetail = (caseId) => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_CRM_PENDING_CASE_DETAIL
  });
  const _CRMService = new CRMService();
  return await _CRMService.getCRMCaseDetail(caseId)
  .then(
    response => {
      // Order case mails by date
      let data = response?.data || [];
      if(Object.keys(data).length > 0 && data?.mails?.length > 1 ){
        data.mails = data.mails.sort((a,b) => {
          return new Date(a.date) - new Date(b.date);
        })
      }
      dispatch(
        actionSuccessfulCRMPendingCaseDetailFetch(
          FETCH_CRM_PENDING_CASE_DETAIL, data, caseId
        )
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(
        actionFailedCRMPendingCaseDetailFetch(
          FETCH_CRM_PENDING_CASE_DETAIL
        )
      );
      return parseResponse(error);
    }
  )
}

export const sendNewCRMPendingCaseMessage = (currentCaseId, data) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.postCRMCaseMessage(currentCaseId, data)
  .then(
    response => {
      const d = new Date();
      const date = d.getHours() + ":" + d.getMinutes() 
        + ":" + d.getSeconds() + " " + d.getDay()
        + "/" + d.getMonth() + "/" + d.getFullYear()
      dispatch({
        type: ADD_CRM_PENDING_CASE_MESSAGE,
        payload: response.data,
        currentCaseId
      });
      return parseResponse(response);
    }
  ).catch(
    error => {
      dispatch(
        actionFailedCRMPendingCaseDetailFetch(
          FETCH_CRM_PENDING_CASE_DETAIL
        )
      );
      return parseResponse(error);
    }
  );
}

export const fetchCRMPendingCases = (currentSectionId) => async (dispatch, getState) => {
  const _CRMService = new CRMService();
  return await _CRMService.getCRMPendingCases(currentSectionId)
  .then(
    response => {
      dispatch({
        type: FETCH_CRM_PENDING_CASES,
        payload: {
          data: response.data
        },
        currentSectionId
      });
    }
  ).catch(
    error => {
      dispatch(
        actionFailedCRMPendingCaseDetailFetch(
          FETCH_CRM_PENDING_CASE_DETAIL
        )
      );
      return parseResponse(error);
    }
  )
}