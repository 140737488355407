import React from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useTranslation } from 'react-i18next';

export const validate = (value, options) => {
  const minUploads = options.min || 0;
  const maxUploads = options.max || 0;
  const isIterable = Symbol.iterator in Object(value);
  const minMessage = options.minMessage || 'minimum files is';
  const maxMessage = options.maxMessage || 'maximum files is';

  if (minUploads > 0) {
    if (!isIterable) {
      return `${minMessage} ${minUploads}`;
    } else {
      const notNulls = value.filter((x) => x !== null && x !== undefined);
      if (notNulls.length < minUploads) {
        return `${minMessage} ${minUploads}`;
      }
      if (maxUploads > 0 && notNulls.length > maxUploads) {
        return `${maxMessage} ${maxUploads}`;
      }
    }
  }
};

const MultiUploadButton = ({
  attachmentsLength = 0,
  max = Infinity,
  onChange,
  fromCreate = false,
}) => {
  const { t } = useTranslation();
  const allowMoreUploads = attachmentsLength < max;

  const handleAddFile = (file) => {
    onChange(file);
  };

  const handleFileLoad = (event) => {
    const reader = new FileReader();
    const targetFile = event.target.files[0];
    reader.onloadend = () => {
      const file = { name: targetFile.name, content: reader.result };
      handleAddFile(file);
    };
    if (targetFile) {
      reader.readAsDataURL(targetFile);
    }
  };

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        id="upload-new-attachment"
        onChange={(event) => handleFileLoad(event)}
        disabled={!allowMoreUploads}
      />
      <label htmlFor="upload-new-attachment">
        {fromCreate ?
          <span>
            <Button
              component="span"
              disabled={!allowMoreUploads}
              startIcon={<AttachFileIcon fontSize="small" />}
            >
              {allowMoreUploads ?
                t('common:crm_attachment_add')
              :
                t('common:crm_attachment_limit')
              }
            </Button>
          </span>
        :
          <Tooltip
            title={allowMoreUploads ?
              t('common:crm_attachment_add')
            :
              t('common:crm_attachment_limit')
            }
          >
            <span>
              <IconButton
                component="span"
                disabled={!allowMoreUploads}
                style={{padding: "6px"}}
              >
                <AttachFileIcon
                fontSize="small"
                />
              </IconButton>
            </span>
          </Tooltip>
        }
      </label>
    </>
  );
};

export default MultiUploadButton;
