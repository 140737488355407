import { FETCH_NEWS, FETCHING_NEWS } from "../actions/types";
import { INITIAL_NEWS_STATE } from "./index";

import _ from 'lodash';


export default (state = INITIAL_NEWS_STATE, action) => {
  switch (action.type) {
    case FETCHING_NEWS:
      return {
        ...state,
        loadingNews: true
      };
    case FETCH_NEWS:
      return {
        ...state,
        loadingNews: false,
        items: action?.payload?.data[0]
      };
    default:
      return state;
  }
};