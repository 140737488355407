import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { history } from '../../../config';
import { InfoContainer } from "../../../components";
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import MultiUploadButton from "../components/MultiUploadButton";
import RequirementDoc from "./components/RequirementDoc";
import {
  fetchCRMCaseSectionsAttachmentsList,
  fetchCRMCaseCategories,
  fetchCRMContracts,
  cleanSectionsAttachmentsList,
  downloadCRMCaseSectionsAttachment,
  sendNewCRMCase,
  fetchCRMCases,
} from "../../../actions";
import saveAs from 'file-saver';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Fade,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Settings from "../../../settings.yml";
import useCRM from "../hooks/useCRM";
import ShowAddedAttachments from "../components/ShowAddedAttachments";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: 0,
  },
  header: {
    textAlign: "center",
  },
  title: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const CRMCreate = () => {

  // MIRAR ELS ATTACHMENTS QUE ELS FA MALAMENT ENCARA

  const classes = useStyles();
  const { t } = useTranslation();
  const { sectionId } = useParams();
  const dispatch = useDispatch();

  const {currentSection} = useCRM(sectionId);
  const sectionAttachments = useSelector(
    (state) => state?.CRMCases?.sections_attachments ?? []
  );
  const categories = useSelector((state) => state?.CRMCases?.categories ?? []);
  const sendingCRMCase = useSelector(
    (state) => state?.CRMCases?.sendingCRMCase ?? false
  );
  const contracts = useSelector((state) => state?.contracts?.items ?? {});

  const [displayCategory, setCategory] = useState("");
  const [displayPolicy, setPolicy] = useState("");
  const [displayTitle, setTitle] = useState("");
  const [displayDescription, setDescription] = useState("");
  const [displayCheckedRelatedContract, setCheckedRelatedContract] = useState(false);
  const [displayAdditionalAttachments, setAdditionalAttachments] = useState([]);
  const [displayAnchorEl, setAnchorEl] = useState(null);
  const [displayUploadedAttachments, setUploadedAttachments] = useState([]);
  const [displayRequiredUploadedAttachments, setRequiredUploadedAttachments] = useState(0);
  const [displaySection, setSection] = useState("");
  const [displaySectionDescription, setSectionDescription] = useState("");
  const [displaySectionName, setSectionName] = useState("");
  const [displayRequiredAttachments, setRequiredAttachments] = useState(0);

  useEffect(() => {
    dispatch(cleanSectionsAttachmentsList);
    const limit = parseInt(Settings.contractsLimitPerGet);
    dispatch(fetchCRMContracts(0, limit));

  }, []);

  useEffect(() => {
    let newRequiredAttachments = 0;
    sectionAttachments.forEach((att) => {
      if (att.required === "must_fill") {
        newRequiredAttachments++;
      }
    });

    setRequiredAttachments(newRequiredAttachments);

  }, [sectionAttachments]);

  const handleChangeSection = (event) => {
    const sectionData = getSectionDataFromValueId(event.target.value);
    setSection(event.target.value);
    setSectionDescription(sectionData.selectedDescription);
    setSectionName(sectionData.selectedName);
    updateCategoriesAttachments(event);
  };

  const updateCategoriesAttachments = (event) => {
    dispatch(fetchCRMCaseCategories(event.target.value));
    dispatch(fetchCRMCaseSectionsAttachmentsList(event.target.value));
  }

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleChangePolicy = (_event, value) => {
    setPolicy((value !== null) ? value.id : null);
  };

  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleChangeRelatedContract = (event) => {
    setCheckedRelatedContract(event.target.checked);
  };

  const handleUpload = (file) => {
    const uploadsArr = [...displayAdditionalAttachments];
    uploadsArr.push(file);
    const attachmentsList = uploadsArr.filter((file) => file && file.name);
    setAdditionalAttachments(attachmentsList);
  };

  const handleRemoveFile = (position) => {
    const uploadsArr = [...displayAdditionalAttachments];
    const attachmentsList = uploadsArr.filter((file, idx) => idx !== position);
    setAdditionalAttachments(attachmentsList);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      'polissa_id': displayPolicy,
      'categ_id': displayCategory,
      'title': displayTitle,
      'section_id': displaySection,
      'message': displayDescription,
      'attachments': [...displayUploadedAttachments.filter(Boolean), ...displayAdditionalAttachments]
    }
    dispatch(sendNewCRMCase(data))
    .then(
      response => {
        if(response[0] === 200){
          dispatch(fetchCRMCases(sectionId))
            .then(() => 
              history.push(`/solicitudes/${sectionId}/${response[1].id}/`)
          );
        };
      }
    );
  }
  
  const handleDownloadSectionAttachment = (id) => {
    dispatch(downloadCRMCaseSectionsAttachment(id)).then(
      response => {
        if(response[0] === 200){
          saveAs(response[1].content, response[1].name);
        }
      }
    );
    handleCloseModel()
  }

  const handleCloseModel = () => {
    setAnchorEl(null);
  };

  const getSectionDataFromValueId = (value) => {
    const currentSectionInfo = currentSection.section_info.filter(element => element.id === value);
    return currentSectionInfo.length ? {
      selectedName: currentSectionInfo[0].name,
      selectedDescription: currentSectionInfo[0].ov_description,
    } : {}
  }

  const handleUploaded = (file, index, required) => {
    if (file){
      let newUploadsArray = [...displayUploadedAttachments];
      newUploadsArray.push(file);
      setUploadedAttachments(newUploadsArray);
      if(required){
        setRequiredUploadedAttachments(displayRequiredUploadedAttachments + 1);
      }
    } else {
      setUploadedAttachments(displayUploadedAttachments.filter(
        (element) => element.index !== index)
      );
      if(required){
        setRequiredUploadedAttachments(displayRequiredUploadedAttachments - 1);
      }
    }
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <div style={{ margin: "0 1%", padding: "1%" }}>
          {currentSection && currentSection.section_ids.length > 0 ? (
            <div>
              <TextField
                id="select_section"
                select
                label={t("common:section")}
                value={displaySection}
                fullWidth
                onChange={handleChangeSection}
                required
                style={{ marginBottom: "3%" }}
              >
                {currentSection.section_info.map((info, idx) => (
                  <MenuItem key={idx} value={info.id}>
                    {info.name}
                  </MenuItem>
                ))}
              </TextField>
              {displaySectionDescription && displaySectionName && (
                <div>
                  <Typography variant="h5" gutterBottom>
                    {displaySectionName}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {displaySectionDescription}
                  </Typography>
                </div>
              )}
              <br />
              <Grid container>
                {sectionAttachments.map(
                  (att, idx) => (
                    <Grid
                      item
                      md={12}
                      key={att.id}
                      style={{marginBottom: "16px", minHeight: "64px"}}
                    >
                      <RequirementDoc
                        downloadFunction={handleDownloadSectionAttachment}
                        attachmentId={att.id}
                        fileLabel={att.attachment_name}
                        required={att.required}
                        handleUploaded={handleUploaded}
                        index={idx}
                        downloadLabel={att.required !== 'information' ?
                          t("common:template")
                        :
                          t("common:download")
                        }
                      />
                  </Grid>
                  )
                )}
              </Grid>
              <br />
            </div>
          ) : null}

          {categories?.length > 0 && (
            <TextField
              id="select_category"
              select
              label={t("common:category")}
              value={t("common:" + displayCategory)}
              fullWidth
              onChange={handleChangeCategory}
              style={{ marginBottom: "3%" }}
              required
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {Object.keys(contracts).length > 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={displayCheckedRelatedContract}
                  onChange={handleChangeRelatedContract}
                  name="checkedRelatedContract"
                  color="primary"
                />
              }
              label={t("common:crm_related_to_contract")}
            />
          )}

          {Object.keys(contracts).length > 0 && displayCheckedRelatedContract && (
            <Autocomplete
              id="select_policy"
              options={Object.values(contracts)}
              onChange={handleChangePolicy}
              getOptionLabel={(option) => {return (option === "") ?
                ""
              :
                (option.name + " - " + option.cups.full_address)}
              }
              style={{ marginBottom: "3%" }}
              renderInput={(params) =>
                <TextField 
                  {...params} 
                  label={t("common:contract_singular")}
                />
              }
            />
          )}

          <TextField
            id="asunto"
            label={t("common:subject")}
            fullWidth
            required
            onChange={handleChangeTitle}
            style={{ marginBottom: "3%" }}
          />
          <TextField
            id="descripcion"
            label={t("common:description")}
            fullWidth
            multiline
            rows={8}
            required
            onChange={handleChangeDescription}
            style={{marginBottom: '3%'}}
          />
          <ShowAddedAttachments
            attachments={displayAdditionalAttachments}
            maxAttachmentLength={20}
            handleRemoveFile={handleRemoveFile}
          />
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <MultiUploadButton
                attachmentsLength={displayAdditionalAttachments.length}
                max={3}
                onChange={handleUpload}
                fromCreate={true}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                endIcon={sendingCRMCase ?
                  <CircularProgress size={20}/>
                :
                  <SendIcon />
                }
                type="submit"
                disabled={
                  !(
                    !sendingCRMCase &&
                    displaySection &&
                    displayTitle &&
                    displayDescription &&
                    displayRequiredAttachments === displayRequiredUploadedAttachments
                  )
                }
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                {sendingCRMCase ? t("common:sending") : t("common:send")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }

  return (
    <Fade in={true}>
      <InfoContainer
        maxWidth={"lg"}
        title={t("common:crm_new_case")}
        subtitle={t("common:contract_list_subtitle")}
        className={classes.container}
        goBackArrow={`/solicitudes/${sectionId}`}
      >
        {renderForm()}
      </InfoContainer>
    </Fade>
  );
}

export default CRMCreate;