import React from 'react';
import { Grid } from "@material-ui/core";
import CRMAlertMessage from './CRMAlertMessage';

const CRMSubtitle = ({id, status}) => {
  return (
    <Grid container item spacing={1}>
      <CRMAlertMessage id={id} status={status} />
    </Grid>
  );
}

export default CRMSubtitle