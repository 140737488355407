import React from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import CRMTitle from "../../components/CRMTitle";
import { InfoContainer, LoadingSkeletons } from "../../../../components";
import useCRM from "../../hooks/useCRM";
import DetailRenderAttachments from "./DetailRenderAttachments";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: 0
  }
}));

const AttachmentsList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { 
    sectionId,
    caseId
  } = useParams();

  const {loadingCaseDetail, currentCase} = useCRM(sectionId, caseId);

  const attachments = currentCase?.details?.attachments;

  return (
    <Fade in={true}>
      <InfoContainer
        maxWidth={"lg"}
        title={<CRMTitle currentCase={currentCase} />}
        subtitle={t("common:crm_attachment_subtitle")}
        className={classes?.container}
        goBackArrow={`/solicitudes/${sectionId}/${caseId}`}
      >
        { loadingCaseDetail || !attachments ?
          <LoadingSkeletons fullHeight/>
        : 
          <DetailRenderAttachments
            attachments={attachments}
            currentCaseId={caseId}
            isAttachmentsList={true}
          />
        }
      </InfoContainer>
    </Fade>
  );
}

export default AttachmentsList;