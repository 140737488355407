import React from 'react';
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import {makeStyles, useTheme } from "@material-ui/core/styles";
import { grey } from '@material-ui/core/colors';
import { formatDisplayDate } from '../../utils/timeManagement';

const useStyles = makeStyles(theme => ({
  dateBox: {
    textAlign: "center",
    margin: "20px 0",
    color: grey[800],
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    }
  },
  dateBoxContent: {
    display: "inline-block",
    backgroundColor: grey[200],
    padding: "5px 20px",
    borderRadius: "20px",
    filter: "drop-shadow(-1px -1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.15))",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    }
  },
  dateBoxContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  dateBoxTooltip: {
    backgroundColor: grey[50]
  }
}));

const MessageDate = ({date, id}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      id={`dateBox-${id}`}
      className={`
        ${classes.dateBoxContainer}
        ${classes.dateBox}
        `}
      >
      <Box
        className={`
          ${classes.dateBoxContent}
          ${id === 'tooltip' ? classes.dateBoxTooltip : ""}
        `}
      >
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: isMobile ? "0.8rem" : "1rem"
          }}
        >
          {formatDisplayDate(date)}
        </Typography>
      </Box>
    </Box>
  )
}

export default MessageDate