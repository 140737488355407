import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import { LinkRouter } from "../../../../components";
import Breadcrumb from "./Breadcrumb";
import UserSection from "./UserSection";
import { BarLogo } from "../../../../config";
import { fetchCRMCaseSections, fetchCRMAllPendingCases } from "../../../../actions";

const useStyles = makeStyles(theme => ({
  mainAppbar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar,
  },
  breadcrumbAppbar: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.dark
  },
  flexGrow: {
    flexGrow: 1
  },
  title: {
    color: theme.palette.primary.contrastText,
    marginLeft: '5vw'
  },
}));

const Topbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const sections = useSelector(
    (state) => state.CRMCases?.sections
  );

  const allPendingCases = useSelector(
    (state) => state.CRMCases?.allPendingCases
  );

  useEffect(() => {
    if (!sections.length) {
      dispatch(fetchCRMCaseSections())
    }

    if (!allPendingCases.length) {
      dispatch(fetchCRMAllPendingCases())
    }
  }, [])

  return (
    <div>
      <AppBar
        className={classes.mainAppbar}
        position="relative"
      >
        <Toolbar>
          <LinkRouter to="/">
            <BarLogo />
          </LinkRouter>
          <div className={classes.flexGrow}/>
          <UserSection 
            sections={sections}
            allPendingCases={allPendingCases}
            />
        </Toolbar>
      </AppBar>
      <AppBar
        className={classes.breadcrumbAppbar}
        position="relative"
      >
        <Toolbar variant="dense">
          <Breadcrumb/>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Topbar;
