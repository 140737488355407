import React from 'react';
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { history } from '../../../../config';
import AddIcon from '@material-ui/icons/Add';

const NewCaseButton = () => {
  const { t } = useTranslation();
  const { sectionId } = useParams();

  return (
    <Button
      variant="contained" 
      color="primary"
      onClick={() => {history.push(`/solicitudes/${sectionId}/crear`)}}
      startIcon={<AddIcon />}
    >
      {t('common:crm_new_case')}
    </Button>
  );
}

export default NewCaseButton