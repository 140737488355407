import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Fade, makeStyles } from "@material-ui/core";
import { InfoContainer, LoadingSkeletons } from "../../../components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CRMTitle from "../components/CRMTitle";
import { fetchCRMAllPendingCases } from "../../../actions";
import { fetchCRMPendingCaseDetail } from "../../../actions/CRMActions";
import DetailAnswerBox from "../Detail/components/DetailAnswerBox";
import DetailConversation from "../Detail/components/DetailConversation";
import StatusIndicator from "../Detail/components/StatusIndicator";
import PendingCaseAttachmentsListButton from "./components/PendingCaseAttachmentsListButton";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: 0
  }
}));

const CRMPendingCasesDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { caseId } = useParams();

  const dispatch = useDispatch();

  const allPendingCases = useSelector(
    (state) => state?.CRMCases?.allPendingCases
  );

  const currentCase = useSelector(
    (state) => state?.CRMCases?.allPendingCases?.filter(
      caseElement => caseElement.id === parseInt(caseId)
    )[0]
  );

  const loadingPendingCasesDetail = useSelector(
    (state) => state?.CRMCases?.loadingPendingCasesDetail
  );

  useEffect(() => {
    if (!allPendingCases?.length) {
      dispatch(fetchCRMAllPendingCases());
    }
    else {
      dispatch(fetchCRMPendingCaseDetail(caseId));
    }
  }, [allPendingCases?.length])

  const hasAttachments = currentCase?.details?.attachments.length

  return (currentCase?.details ?
      <Fade in={true}>
        <InfoContainer
          maxWidth={"lg"}
          title={<CRMTitle currentCase={currentCase} />}
          subtitle={t("common:crm_detail_subtitle")}
          className={classes.container}
          goBackArrow={`/pendientes`}
          actionButton={hasAttachments ?
              <PendingCaseAttachmentsListButton attachmentsNumber={hasAttachments}/>
            :
              null
          }
        >
          <Box>
            <StatusIndicator status={currentCase?.status}/>
            <Box mt={2} style={{position: "relative"}}>
              <DetailConversation currentCase={currentCase} />
            </Box>
            <DetailAnswerBox currentCase={currentCase} fromPending={true}/>
          </Box>
        </InfoContainer>
      </Fade>
    :
      <Fade in={true}>
        <Box>
          {loadingPendingCasesDetail &&
            <LoadingSkeletons fullHeight/>
          }
        </Box>
    </Fade>
  )
}

export default CRMPendingCasesDetail;