import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import saveAs from 'file-saver';
import { downloadAttachment } from '../../../../actions';
import { grey } from '@material-ui/core/colors';
import { Person } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { formatDisplayDate, formatTime } from '../../utils/timeManagement';

const DetailRenderAttachments = ({
  attachments,
  currentCaseId,
  isAttachmentsList=false
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();

  const userName = useSelector((state) => state?.user?.name);
  const [displayDownloadingAttachment, setDownloadingAttachment] = useState({});

  const downloadAttachments = (attachment) => {
    setDownloadingAttachment({
      ...displayDownloadingAttachment,
      [attachment.id]: {downloading: true, error: false}
    });
    dispatch(downloadAttachment(
      currentCaseId,
      attachment.id
    )).then(
      response => {
        if(response[0] === 200){
          saveAs(response[1].content, response[1].file_name);
          setDownloadingAttachment({
            ...displayDownloadingAttachment,
            [attachment.id]: {downloading: false, error: false}
          });
        }
        else{
          setDownloadingAttachment({
            ...displayDownloadingAttachment,
            [attachment.id]: {downloading: false, error: true}
          });
        }
      }
    );
  }

  const getIconExtension = (fileName) => {
    const fileExtension = fileName?.split('.').pop();
    switch(fileExtension) {
      case "pdf":
        return "-pdf"
      case "xlsx":
        return "-excel"
      case "png":
      case "jpg":
        return "-image"
      case "csv":
        return "-csv"
      default:
        return ""
    }
  }

  return (
    attachments.map((attachment, idx) => (
      <Card
        key={idx}
        style={{
          borderRadius: "5px",
          marginBottom: "4px",
        }}
      >
        <CardActionArea>
          <CardContent
            onClick={() => downloadAttachments(attachment)}
            style={{
              padding: !isAttachmentsList && isMobile ? 8 : undefined,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={!isAttachmentsList && isMobile ? 1 : 2}
              wrap="nowrap"
            >
              { isAttachmentsList &&
                <Grid item>
                  <Avatar
                    style={{
                      backgroundColor: attachment.creator === 'ov' ?
                        theme.palette.primary.main
                      :
                        grey[600],
                      width: isMobile ? "32px" : undefined,
                      height: isMobile ? "32px" : undefined
                    }}
                  >
                    {attachment.creator === 'ov' ?
                      userName?.[0] || ''
                    :
                      <Person />
                    }
                  </Avatar>
                </Grid>
              }
              <Grid item>
                <i
                  className={`
                    far fa-file${getIconExtension(attachment.file_name)}
                  `}
                  style={{
                    fontSize: isMobile ?
                      !isAttachmentsList ?
                        16
                      :
                        24
                    :
                      30
                  }}
                />
              </Grid>
              <Grid item style={{flexGrow: 1}}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: isMobile ?
                      !isAttachmentsList ?
                        "8pt"
                      :
                        "0.9rem"
                    :
                      undefined,
                  }}
                >
                  {isMobile ?
                    !isAttachmentsList ?
                      attachment.name.length > 9 ?
                        `${attachment.name.substring(0, 9)}...`
                      :
                        attachment.name
                    :
                      attachment.name.length > 20 ?
                        `${attachment.name.substring(0, 20)}...`
                      :
                        attachment.name
                  :
                    attachment.name
                  }
                  {!isMobile && attachment.file_name !== attachment.name ?
                    ` - ${attachment.file_name}`
                  : ''}
                </Typography>
                { isAttachmentsList &&
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: "lighter",
                      color: grey[800],
                      fontSize: isMobile ? "0.8rem" : undefined
                    }}
                  >
                    {formatDisplayDate(attachment.date)} - {formatTime(attachment.date)}
                  </Typography>
                }
              </Grid>
              <Grid item>
                {displayDownloadingAttachment?.[attachment.id]?.downloading ?
                  <CircularProgress
                    style={{
                      fontSize: isMobile ?
                        !isAttachmentsList ?
                          16
                        :
                          24
                      :
                        30
                    }}
                  />
                :
                  <GetAppIcon
                    style={{
                      fontSize: isMobile ?
                        !isAttachmentsList ?
                          16
                        :
                          24
                      :
                        30
                    }}
                  />
                }
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    ))
  );
}

export default DetailRenderAttachments