import React, { useEffect } from "react";
import { Fade, makeStyles, Table, TableBody } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { fetchCRMAllPendingCases } from "../../../actions";
import { useTranslation } from "react-i18next";
import { InfoContainer, LoadingSkeletons } from "../../../components";
import CRMListCase from "../List/components/CRMListCase";
import { InfoContainerBody } from "../../../components/InfoContainerBody";
import { history } from "../../../config";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "70vh",
    padding: 0
  }
}));

const CRMPendingCasesList = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const allPendingCases = useSelector(
    (state) => state.CRMCases?.allPendingCases
  );

  const loadingPendingCasesList = useSelector(
    (state) => state?.CRMCases?.loadingPendingCasesList
  );

  useEffect(() => {
    if (!allPendingCases?.length) {
      dispatch(fetchCRMAllPendingCases())
    }
  }, [])

  const hasCases = allPendingCases?.length > 0 ?? false;

  const renderListCases = () => {
    if(hasCases){
      return allPendingCases?.map((caseElement) => (
        <CRMListCase
          key={caseElement.id}
          caseItem={caseElement}
          handleClick={
            () => {history.push(`/pendientes/${caseElement.id}`)}
          }
          isPendingCase={false}
        />
      ));
    }
    return null;
  }

  return (
    <Fade in={true}>
      <InfoContainer
        maxWidth={"lg"}
        title={t("common:crm_pending_cases_title")}
        subtitle={t("common:crm_pending_requested_subtitle")}
        className={classes?.container}
        goBackArrow={'/inicio'}
      >
        { loadingPendingCasesList ? (
          <LoadingSkeletons fullHeight/>
        ) : (
          <>
            { hasCases ? (
              <Table>
                <TableBody>
                  {renderListCases()}
                </TableBody>
              </Table>
            ) : (
              <InfoContainerBody
                maxWidth={"lg"}
                subtitle={t("common:crm_without_data")}
                className={classes.container}
              />
            )}
          </>
        )}
      </InfoContainer>
    </Fade>
  )
}

export default CRMPendingCasesList;