import _ from "lodash";
import NewsService from "../service/NewsService";
import {
  FETCH_NEWS,
  FETCHING_NEWS
} from "./types";

import {
  FETCH_NEWS_SUCCESSFUL_MSG,
  FETCH_NEWS_FAILURE_MSG
} from './messages';
import { parseResponse } from "../service";

export const actionSuccessfulDataFetch = (actionType, data) => {
  return {
    type: actionType,
    payload: {
      data: data,
      message: FETCH_NEWS_SUCCESSFUL_MSG
    }
  }
};

export const actionFailedDataFetch = actionType => {
  return {
    type: actionType,
    payload: {
      data: null,
      message: FETCH_NEWS_FAILURE_MSG
    }
  }
};

export const fetchNews = () => async (dispatch, getState) => {
  dispatch({
    type: FETCHING_NEWS
  });
  const _NewsService = new NewsService();
  return await _NewsService.getNews()
  .then(
    response => {
      dispatch(
        actionSuccessfulDataFetch(FETCH_NEWS, response?.data)
      )
      return parseResponse(response);
    }
  )
  .catch(
    error => {
      dispatch(actionFailedDataFetch(FETCH_NEWS));
      return parseResponse(error);
    }
  )
};